// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qcRCmMGm3KTu6bvtujD1{display:block}.rsGAM6Uwj4lIn_ePUkGC:after{width:100%;display:block;content:"";border-top:1px solid rgba(67,75,79,.1490196078);box-shadow:0px 1px 0px #fff}.m3gBDF89pOb23EyDaJ_a{padding-top:0 !important}.m3gBDF89pOb23EyDaJ_a>ul:first-child{margin-top:0px}.wHrG0Xpis86VRexUNZYQ .VK1S95jiT_fslGZ_rf6M{gap:7px;display:flex;width:100%;flex-wrap:wrap}.wHrG0Xpis86VRexUNZYQ .VK1S95jiT_fslGZ_rf6M .DGmLFuvT9xhnUSIEl9Zq{background-color:#434B4FA6;padding:12px 15px 12px 15px;border-radius:10px 10px 0 0;color:#f2f2f0}@media(min-width: 0){.wHrG0Xpis86VRexUNZYQ .VK1S95jiT_fslGZ_rf6M .DGmLFuvT9xhnUSIEl9Zq{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 400px){.wHrG0Xpis86VRexUNZYQ .VK1S95jiT_fslGZ_rf6M .DGmLFuvT9xhnUSIEl9Zq{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 768px){.wHrG0Xpis86VRexUNZYQ .VK1S95jiT_fslGZ_rf6M .DGmLFuvT9xhnUSIEl9Zq{font-size:1.8rem;line-height:2.8rem}}@media(min-width: 1366px){.wHrG0Xpis86VRexUNZYQ .VK1S95jiT_fslGZ_rf6M .DGmLFuvT9xhnUSIEl9Zq{font-size:1.9rem;line-height:2.8rem}}.wHrG0Xpis86VRexUNZYQ .VK1S95jiT_fslGZ_rf6M .DGmLFuvT9xhnUSIEl9Zq.RmHz8rCm50NejpKQGBGm{background-color:#434b4f}.wHrG0Xpis86VRexUNZYQ .QMpgmf4Xf_wsHWjYVHHW{display:flex;flex-direction:column;width:100%}.wHrG0Xpis86VRexUNZYQ .QMpgmf4Xf_wsHWjYVHHW .x45qbO9mm_g8WCQ0KDTs{font-weight:700;text-align:left;padding:46px 0 12px 0;display:flex;justify-content:space-between}.wHrG0Xpis86VRexUNZYQ .QMpgmf4Xf_wsHWjYVHHW .nqn9F6yBbmi52sc8kL0E{display:flex;justify-content:space-between;width:100%;padding:24px 0}.wHrG0Xpis86VRexUNZYQ .QMpgmf4Xf_wsHWjYVHHW .nqn9F6yBbmi52sc8kL0E>span:first-child{text-align:left}.wHrG0Xpis86VRexUNZYQ .QMpgmf4Xf_wsHWjYVHHW .nqn9F6yBbmi52sc8kL0E>span:last-child{text-align:right}`, ""]);
// Exports
export var wrapper = `qcRCmMGm3KTu6bvtujD1`;
export var seeMoreUnderline = `rsGAM6Uwj4lIn_ePUkGC`;
export var noTopPadding = `m3gBDF89pOb23EyDaJ_a`;
export var equipmentSection = `wHrG0Xpis86VRexUNZYQ`;
export var equipmentSectionIndicators = `VK1S95jiT_fslGZ_rf6M`;
export var equipmentTabIndicator = `DGmLFuvT9xhnUSIEl9Zq`;
export var activeEquipmentTabIndicator = `RmHz8rCm50NejpKQGBGm`;
export var equipmentTabContainer = `QMpgmf4Xf_wsHWjYVHHW`;
export var equipmentTabContainerTitle = `x45qbO9mm_g8WCQ0KDTs`;
export var equipmentTabItem = `nqn9F6yBbmi52sc8kL0E`;
export default ___CSS_LOADER_EXPORT___;
